import type { HttpClient } from './http';
import type {
  TActivateFastlaneMonadQuestResDto,
  TAirdropEarnNftReqDto,
  TCreateEventReqDto,
  TCreateUserQuestReqDto,
  TCreateUserQuestResDto,
  TGetInviteAcceptedCount,
  TGetLeaderboardResDto,
  TGetLoginStreakResDto,
  TGetReferralCountByCampaignTypeReqDto,
  TGetReferralCountByCampaignTypeResDto,
  TGetUserQuestByQuestTypeReqDto,
  TGetUserQuestByQuestTypeResDto,
  TGetUserRankResDto,
  TInsertUserQuestTaskReqDto,
  TUploadEarnStatsReqDto,
} from './dtos';

export class EarnService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getUserEarnStats(
  ): Promise<any> {
    try {
      const result = await this.httpClient.get('/earns/get-stats');
      return result;
    } catch (error) {
      //
    }
    return null;
  }

  public uploadEarnStats({
    payload,
  }: TUploadEarnStatsReqDto) {
    return this.httpClient.post('/earns/upload-stats', {
      payload,
    });
  }

  public airdropNft({
    category,
  }: TAirdropEarnNftReqDto) {
    return this.httpClient.post('/earns/airdrop-nft', {
      category,
    });
  }

  public getLeaderboard(): Promise<TGetLeaderboardResDto> {
    return this.httpClient.get('/earns/leaderboard');
  }

  public getUserRank(): Promise<TGetUserRankResDto> {
    return this.httpClient.get('/earns/user-rank');
  }

  public createEvent({ event } : TCreateEventReqDto): Promise<void> {
    return this.httpClient.post('/event', {
      event,
    });
  }

  public getAppVisitCount(): Promise<TGetLoginStreakResDto> {
    return this.httpClient.get('/earns/login-streak');
  }

  public getInviteAcceptedCount(): Promise<TGetInviteAcceptedCount> {
    return this.httpClient.get('/earns/invite-count');
  }

  public createUserQuest(payload: TCreateUserQuestReqDto): Promise<TCreateUserQuestResDto> {
    return this.httpClient.post('/user-quests', payload);
  }

  public fetchUserQuestByQuestType(payload: TGetUserQuestByQuestTypeReqDto): Promise<TGetUserQuestByQuestTypeResDto> {
    return this.httpClient.get(`/user-quests/type/${payload.type}`);
  }

  public updateUserQuestCompletedStep(payload: TInsertUserQuestTaskReqDto): Promise<void> {
    return this.httpClient.patch(`/user-quests/${payload.questId}/complete`, payload);
  }

  public completedJoinGroupInMonadQuest(payload: any): Promise<void> {
    return this.httpClient.post('/quests/monad/join-group/complete', payload);
  }

  public activateMonadRabot(payload: any): Promise<void> {
    return this.httpClient.post('/quests/monad/activate-rabot', payload);
  }

  public getReferralCountByCampaignType(payload: TGetReferralCountByCampaignTypeReqDto): Promise<TGetReferralCountByCampaignTypeResDto> {
    return this.httpClient.get(`/user-quests/count/referral/${payload.questCampaignType}`);
  }

  public activateFastlaneRabot(payload: any): Promise<TActivateFastlaneMonadQuestResDto> {
    return this.httpClient.post('/quests/fastlane-monad/activate-rabot', payload);
  }

  public getBatchIdForFastlaneRabot(): Promise<{
    batchId: string;
  }> {
    return this.httpClient.get('/quests/fastlane-monad/batch-id');
  }
}
