export enum QuestTaskTypeEnum {
  RAPIDNODE_COPY_CODE = 'RAPIDNODE_COPY_CODE',
  MINTAIR_COPY_CODE = 'MINTAIR_COPY_CODE',
  MONAD_JOIN_TELEGRAM = 'MONAD_JOIN_TELEGRAM',
  MONAD_REFER_FRIEND = 'MONAD_REFER_FRIEND',
  MONAD_FUND_MONAD_RABOT = 'MONAD_FUND_MONAD_RABOT',
  FASTLANE_JOIN_TELEGRAM = 'FASTLANE_JOIN_TELEGRAM',
  FASTLANE_ACTIVATE_RABOT = 'FASTLANE_ACTIVATE_RABOT',
  FASTLANE_REFER_FRIEND = 'FASTLANE_REFER_FRIEND',
}
