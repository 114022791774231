/* eslint-disable react/jsx-props-no-spreading */
import React from "../../../../../lib/teact/teact";

export const HowToClaim1 = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="1"
        width="23"
        height="23"
        rx="11.5"
        fill="var(--color-secondary)"
      />
      <rect
        x="0.5"
        y="1"
        width="23"
        height="23"
        rx="11.5"
        stroke="currentColor"
      />
      <path
        d="M15.4999 9.67602C15.4649 9.67018 15.424 9.67018 15.389 9.67602C14.584 9.64685 13.9424 8.98768 13.9424 8.17102C13.9424 7.33685 14.6132 6.66602 15.4474 6.66602C16.2815 6.66602 16.9524 7.34268 16.9524 8.17102C16.9465 8.98768 16.3049 9.64685 15.4999 9.67602Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8993 13.9228C15.6985 14.0569 16.5793 13.9169 17.1977 13.5028C18.0202 12.9544 18.0202 12.0561 17.1977 11.5078C16.5735 11.0936 15.681 10.9536 14.8818 11.0936"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.48277 9.67602C8.51777 9.67018 8.55861 9.67018 8.59361 9.67602C9.39861 9.64685 10.0403 8.98768 10.0403 8.17102C10.0403 7.33685 9.36944 6.66602 8.53527 6.66602C7.70111 6.66602 7.03027 7.34268 7.03027 8.17102C7.03611 8.98768 7.67777 9.64685 8.48277 9.67602Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.08318 13.9228C8.28401 14.0569 7.40318 13.9169 6.78484 13.5028C5.96234 12.9544 5.96234 12.0561 6.78484 11.5078C7.40901 11.0936 8.30151 10.9536 9.10068 11.0936"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9999 14.0334C11.9649 14.0276 11.924 14.0276 11.889 14.0334C11.084 14.0043 10.4424 13.3451 10.4424 12.5284C10.4424 11.6943 11.1132 11.0234 11.9474 11.0234C12.7815 11.0234 13.4524 11.7001 13.4524 12.5284C13.4465 13.3451 12.8049 14.0101 11.9999 14.0334Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3024 15.87C9.47992 16.4183 9.47992 17.3167 10.3024 17.865C11.2358 18.4892 12.7641 18.4892 13.6974 17.865C14.5199 17.3167 14.5199 16.4183 13.6974 15.87C12.7699 15.2517 11.2358 15.2517 10.3024 15.87Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
