/* eslint-disable react/jsx-props-no-spreading */
import React from '../../../../../lib/teact/teact';

export const HowToClaim2 = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="1"
        width="23"
        height="23"
        rx="11.5"
        fill="var(--color-secondary)"
      />
      <rect
        x="0.5"
        y="1"
        width="23"
        height="23"
        rx="11.5"
        stroke="currentColor"
      />
      <path
        d="M9.1248 8.94912L14.4311 7.18037C16.8123 6.38662 18.1061 7.68662 17.3186 10.0679L15.5498 15.3741C14.3623 18.9429 12.4123 18.9429 11.2248 15.3741L10.6998 13.7991L9.1248 13.2741C5.55605 12.0866 5.55605 10.1429 9.1248 8.94912Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8184 13.5309L13.0559 11.2871"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
