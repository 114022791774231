import type { HttpClient } from './http';
import type {
  TCreateBatchReqDto,
  TCreateBatchResDto,
  TCreateRabotTransactionReqDto,
  TCreateUserBotReqDto, TCreateUserBotResDto, TCreateWithdrawBatchTransactionReqDto, TCreateWithdrawBatchTransactionResDto, TGetBatchStatusResDto, TGetRaboDetailsResDto, TGetUserRabotsOverviewResDto, TGetVaultResDto, TListRabotsResDto,
  TListUserBotTransactionsResDto, TWithdrawFromUserBotReqDto,
  TWithdrawFromUserBotResDto,
} from './dtos/rabots';

import {
  CreateUserBotResTransformer,
  GetRabotResTransformer, GetUserRabotsOverviewResTransformer, ListRabotsResTransformer,
  ListUserBotTransactionsResTransformer, WithdrawFromUserBotResTransformer,
} from './transformers';

export class RabotService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getAllRabots() :Promise<TListRabotsResDto> {
    const result = await this.httpClient.get('/rabots');
    return ListRabotsResTransformer.parseAsync(result);
  }

  public async getRabotbyId(
    rabotId:string,
  ):Promise<TGetRaboDetailsResDto> {
    const result = await this.httpClient.get(`/rabots/${rabotId}`);

    return GetRabotResTransformer.parseAsync(result);
  }

  public async getRabotsOverview(
  ):Promise<TGetUserRabotsOverviewResDto> {
    const result = await this.httpClient.get('/rabots/overview');

    return GetUserRabotsOverviewResTransformer.parseAsync(result);
  }

  public async createUserRabotBot(
    payload: TCreateUserBotReqDto,
  ): Promise<TCreateUserBotResDto> {
    const result = await this.httpClient.post('/rabots/user-bot', payload);

    return CreateUserBotResTransformer.parseAsync(result);
  }

  public async createTransaction(
    payload: TCreateRabotTransactionReqDto,
  ): Promise<void> {
    await this.httpClient.post('/rabots/user-bot/transactions', payload);
  }

  public async withdraw(
    payload: TWithdrawFromUserBotReqDto,
  ): Promise<TWithdrawFromUserBotResDto> {
    const result = await this.httpClient.post('/rabots/user-bot/withdraw', payload);

    return WithdrawFromUserBotResTransformer.parseAsync(result);
  }

  public async listTransactions(
    userBotId: string,
  ): Promise<TListUserBotTransactionsResDto> {
    const transactions = await this.httpClient.get(`/rabots/user-bot/transactions/${userBotId}`);

    return ListUserBotTransactionsResTransformer.parseAsync(transactions);
  }

  public async depositFunds(payload: TCreateBatchReqDto): Promise<TCreateBatchResDto> {
    const result = await this.httpClient.post<TCreateBatchResDto, TCreateBatchReqDto >('/batch-transactions/deposit', payload);

    return result;
  }

  public async withdrawFunds(payload: TCreateWithdrawBatchTransactionReqDto): Promise<TCreateWithdrawBatchTransactionResDto> {
    const result = await this.httpClient.post<TCreateWithdrawBatchTransactionResDto, TCreateWithdrawBatchTransactionReqDto >('/batch-transactions/withdraw', payload);

    return result;
  }

  public async getRabotBatchStatus(batchId: string): Promise<TGetBatchStatusResDto> {
    const status = await this.httpClient.get<TGetBatchStatusResDto>(`/batch-transactions/${batchId}/status`);

    return status;
  }

  public async getBasicRabot(): Promise<TGetVaultResDto> {
    const rabot = await this.httpClient.get<TGetVaultResDto>('/rabots/vaults/BASIC');

    return rabot;
  }

  public async getProRabot(): Promise<TGetVaultResDto> {
    const rabot = await this.httpClient.get<TGetVaultResDto>('/rabots/vaults/PRO');

    return rabot;
  }
}
