/* eslint-disable react/jsx-props-no-spreading */
import React from '../../../lib/teact/teactn';

export function CheckIcon2({ ...props }: any) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="4"
        y="0.5"
        width="8"
        height="8"
        rx="4"
        fill="var(--color-secondary)"
        fill-opacity="0.5"
      />
      <g filter="url(#filter1_d_8622_5255)">
        <path
          d="M8.00008 7.83268C9.83341 7.83268 11.3334 6.33268 11.3334 4.49935C11.3334 2.66602 9.83341 1.16602 8.00008 1.16602C6.16675 1.16602 4.66675 2.66602 4.66675 4.49935C4.66675 6.33268 6.16675 7.83268 8.00008 7.83268Z"
          stroke="var(--color-primary)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.58325 4.49997L7.52659 5.44331L9.41659 3.55664"
          stroke="var(--color-primary)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter1_d_8622_5255"
          x="0.166748"
          y="0.666016"
          width="15.6667"
          height="15.666"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8622_5255"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8622_5255"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
