import { createSlice } from '@reduxjs/toolkit';
import type { ethers } from 'ethers';
import { WalletScreens } from '../../../types';
import type { TGetAllBalancesResDto, TWalletBalanceAsset } from '../../apis/dtos';

type TWalletSendSelectedUser = {
  userId: string;
  ethWalletAddress: string;
  solWalletAddress: string;
  aptosWalletAddress?: string;
  suiWalletAddress?: string;
  tonWalletAddress?: string;
} | null;

export type TWalletState = {
  walletBalance?: TGetAllBalancesResDto;
  currentWalletScreen: WalletScreens;
  walletSendSelectedUser: TWalletSendSelectedUser;
  selectedToken?: TWalletBalanceAsset;
  errorTransaction: {
    code: string | null;
    shortMessage: string | null;
  } | null;
  customError: string | null;
  ethAmount: number;
  dollarAmount: number;
  estimatedGas: ethers.BigNumberish | null;
  transactionHash: string;
  isEstimateGasLoading: boolean;
  isSendTransactionLoading: boolean;
  isEthInputMode: boolean;
  inputWalletAddress: string;
};

// Initial state
const initialState: TWalletState = {
  walletBalance: undefined,
  currentWalletScreen: WalletScreens.Main,
  walletSendSelectedUser: null,
  selectedToken: undefined,
  errorTransaction: null,
  customError: null,
  ethAmount: 0,
  dollarAmount: 0,
  estimatedGas: null,
  transactionHash: '',
  isEstimateGasLoading: false,
  isSendTransactionLoading: false,
  isEthInputMode: true,
  inputWalletAddress: '',
};

// Create Redux slice
const walletSlice = createSlice({
  name: 'WALLET',
  initialState,
  reducers: {
    setWalletBalance: (state, action: { payload: { balance: TGetAllBalancesResDto } }) => {
      state.walletBalance = action.payload.balance;
      localStorage.setItem('walletBalance', JSON.stringify(action.payload.balance));
    },
    setWalletToInitialState: (state) => {
      state.currentWalletScreen = WalletScreens.Main;
      state.walletSendSelectedUser = null;
      state.selectedToken = undefined;
      state.errorTransaction = null;
      state.customError = null;
      state.ethAmount = 0;
      state.dollarAmount = 0;
      state.estimatedGas = null;
      state.transactionHash = '';
      state.isEstimateGasLoading = false;
      state.isEthInputMode = true;
      state.inputWalletAddress = '';
      state.isSendTransactionLoading = false;
    },
    setCurrentWalletScreen: (state, action: { payload: { currentWalletScreen: WalletScreens } }) => {
      state.currentWalletScreen = action.payload.currentWalletScreen;
    },
    setWalletSendSelectedUser: (state, action: { payload: { walletSendSelectedUser: TWalletSendSelectedUser } }) => {
      state.walletSendSelectedUser = action.payload.walletSendSelectedUser;
    },
    setSelectedToken: (state, action: { payload: any }) => {
      state.selectedToken = action.payload;
    },
    setErrorTransaction: (state, action: { payload: { code: string | null; shortMessage: string | null } | null }) => {
      state.errorTransaction = action.payload;
    },
    setCustomError: (state, action: { payload: string | null }) => {
      state.customError = action.payload;
    },
    setEthAmount: (state, action: { payload: number }) => {
      state.ethAmount = action.payload;
    },
    setDollarAmount: (state, action: { payload: number }) => {
      state.dollarAmount = action.payload;
    },
    setEstimatedGas: (state, action: { payload: ethers.BigNumberish | null }) => {
      state.estimatedGas = action.payload;
    },
    setTransactionHash: (state, action: { payload: string }) => {
      state.transactionHash = action.payload;
    },
    setIsEstimateGasLoading: (state, action: { payload: boolean }) => {
      state.isEstimateGasLoading = action.payload;
    },
    setIsSendTransactionLoading: (state, action: { payload: boolean }) => {
      state.isSendTransactionLoading = action.payload;
    },
    setIsEthInputMode: (state, action: { payload: boolean }) => {
      state.isEthInputMode = action.payload;
    },
    toggleIsEthInputMode: (state) => {
      state.isEthInputMode = !state.isEthInputMode;
    },
    setInputWalletAddress: (state, action: { payload: string }) => {
      state.inputWalletAddress = action.payload;
    },
  },
});

export const walletActions = walletSlice.actions;
export default walletSlice.reducer;
