/* eslint-disable react/jsx-props-no-spreading */
import React from "../../../lib/teact/teactn";

export function CopyBorderedIcon({ ...props }: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.44538 2.44538C6.06003 2.83074 5.8335 3.48281 5.8335 4.60016V4.8335H7.40016C8.61614 4.8335 9.5974 5.07363 10.262 5.73828C10.9267 6.40292 11.1668 7.38418 11.1668 8.60016V10.1668H11.4002C12.5175 10.1668 13.1696 9.9403 13.5549 9.55494C13.9403 9.16959 14.1668 8.51752 14.1668 7.40016V4.60016C14.1668 3.48281 13.9403 2.83074 13.5549 2.44538C13.1696 2.06003 12.5175 1.8335 11.4002 1.8335H8.60016C7.48281 1.8335 6.83074 2.06003 6.44538 2.44538ZM11.1668 11.1668H11.4002C12.6161 11.1668 13.5974 10.9267 14.262 10.262C14.9267 9.5974 15.1668 8.61614 15.1668 7.40016V4.60016C15.1668 3.38418 14.9267 2.40292 14.262 1.73828C13.5974 1.07363 12.6161 0.833496 11.4002 0.833496H8.60016C7.38418 0.833496 6.40292 1.07363 5.73828 1.73828C5.07363 2.40292 4.8335 3.38418 4.8335 4.60016V4.8335H4.60016C3.38418 4.8335 2.40292 5.07363 1.73828 5.73828C1.07363 6.40292 0.833496 7.38418 0.833496 8.60016V11.4002C0.833496 12.6161 1.07363 13.5974 1.73828 14.262C2.40292 14.9267 3.38418 15.1668 4.60016 15.1668H7.40016C8.61614 15.1668 9.5974 14.9267 10.262 14.262C10.9267 13.5974 11.1668 12.6161 11.1668 11.4002V11.1668ZM4.60016 5.8335C3.48281 5.8335 2.83074 6.06003 2.44538 6.44538C2.06003 6.83074 1.8335 7.48281 1.8335 8.60016V11.4002C1.8335 12.5175 2.06003 13.1696 2.44538 13.5549C2.83074 13.9403 3.48281 14.1668 4.60016 14.1668H7.40016C8.51752 14.1668 9.16959 13.9403 9.55494 13.5549C9.9403 13.1696 10.1668 12.5175 10.1668 11.4002V8.60016C10.1668 7.48281 9.9403 6.83074 9.55494 6.44538C9.16959 6.06003 8.51752 5.8335 7.40016 5.8335H4.60016Z"
        fill="currentColor"
      />
    </svg>
  );
}
