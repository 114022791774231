/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-async-without-await/no-async-without-await */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';

import '../../QuestDetails.scss';

import activateRabotBanner from '../../../../images/earn/quests/fastlane/fastlane-activate-rabot-banner.png';
import claimStep2 from '../../../../images/earn/quests/monad/monad-claim-step-2.png';
import { BatchTransactionStatusEnum, QuestTaskTypeEnum, QuestTypeEnum } from '../../../../apis/enums';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { useStore } from '../../../../teact-redux/useStore';
import { useDispatch } from '../../../../teact-redux/useDispatch';
import EarnActions from '../../../../teact-redux/actions/earn';
import { useEffect, useState } from '../../../../../lib/teact/teact';
import { CheckIcon2 } from '../../../../../components/common/icons/CheckIcon2';
import { Loading } from '../../../../../components/common/icons/Loading';
import { FastlaneQuestStart } from './StartQuest';
import pluto from '../../../../apis/pluto';

export const FastlaneQuestTaskActivateRabot = () => {
  const dispatch = useDispatch();
  const userQuest = useStore((state) => state.earn.userQuest);

  const earnAction = new EarnActions(dispatch);
  const questNotStarted = !userQuest;
  const [isActivatingRabot, setIsActivatingRabot] = useState(false);

  const [status, setStatus] = useState<BatchTransactionStatusEnum | null>(null);
  const [batchId, setBatchId] = useState<string | null>(null);

  const checkTransactionStatus = async (batchIdParam: string): Promise<boolean> => {
    try {
      const batchStatusRes = await pluto.services.rabot.getRabotBatchStatus(batchIdParam);
      const batchStatus = batchStatusRes.status;

      if (batchStatus === BatchTransactionStatusEnum.SUCCESS) {
        setStatus(BatchTransactionStatusEnum.SUCCESS);
        return true; // Stop polling
      } else if (batchStatus === BatchTransactionStatusEnum.FAILED) {
        setStatus(BatchTransactionStatusEnum.FAILED);
        return true; // Stop polling
      }
    } catch (error) {
      console.error('Error fetching batch status:', error);
    }
    return false; // Continue polling
  };

  const pollForSuccess = async (batchIdParam: string) => {
    setIsActivatingRabot(true);
    const startTime = Date.now();
    const timeout = 180000; // 3-minute timeout
    const userQuest1 = useStore((state) => state.earn.userQuest);

    const interval = setInterval(async () => {
      const isComplete = await checkTransactionStatus(batchIdParam);
      const elapsedTime = Date.now() - startTime;

      if (isComplete || elapsedTime >= timeout) {
        clearInterval(interval);

        if (isComplete && (await pluto.services.rabot.getRabotBatchStatus(batchIdParam)).status === BatchTransactionStatusEnum.SUCCESS) {
          await earnAction.updateUserQuestCompletedStep({
            questId: userQuest?.id ?? userQuest1?.id!,
            taskType: QuestTaskTypeEnum.FASTLANE_ACTIVATE_RABOT,
            questType: QuestTypeEnum.FASTLANE_MONAD,
          });
        } else {
          console.warn('Polling timed out after 3 minutes.');
        }

        setIsActivatingRabot(false);
      }
    }, 2000);
  };

  const handleClickActivateRabot = async () => {
    setIsActivatingRabot(true);

    try {
      const { id: newBatchId } = await earnAction.activateFastlaneRabot();
      setBatchId(newBatchId);
      await pollForSuccess(newBatchId);
    } catch (e) {
      console.error('Error activating Rabot or updating quest:', e);
      setIsActivatingRabot(false);
    }
  };

  useEffect(() => {
    const getBatchIdForFastlaneQuest = async () => {
      const batchIdRes = await earnAction.getBatchIdForFastlaneRabot();

      if (batchIdRes.batchId) {
        setBatchId(batchIdRes.batchId);
        await pollForSuccess(batchIdRes.batchId);
      }
    };

    getBatchIdForFastlaneQuest();
  }, []);

  if (questNotStarted) {
    return <FastlaneQuestStart />;
  }

  return (
    <div className="monad-quest-container monad">
      <div className="monad-rewards images-container">
        <h3 className="title">1. Agent Stakes & LPs For You</h3>
        <img src={activateRabotBanner} alt="" />
      </div>
      <div className="rewards__bottom-content monad">
        <div className="how-to-claim monad-rewards">
          <h3 className="title">How to Claim</h3>
          <div className="claim-process">
            <HowToClaimArrow />
            <div className="steps">
              <div className="third-step active-step">
                <HowToClaim3 className="icon" />
                <p className="step-text">Stake and LP in one click!</p>
              </div>
              <div className="first-step">
                {/* <CheckIcon2 className="check-icon" /> */}
                <HowToClaim1 className="icon" />
                <p className="step-text">Join the Rabble Telegram group</p>
              </div>
              <div className="second-step">
                <HowToClaim2 className="icon" />
                <p className="step-text">Invite 2 Friends To Rabble</p>
              </div>
            </div>
          </div>
        </div>
        <div className="claim-card">
          <h3 className="title">Claim Now</h3>
          <p className="sub-title">0/3 Steps Completed</p>
          <div className="claim-step-img">
            <img src={claimStep2} alt="" />
          </div>
          <p className="sub-title">
            Our Rabot will stake some $MON on Fastlane in return for $shMON and
            then deposit it into a Liquidity pool on PancakeSwap. This should take about 15-20s
          </p>
          <button onClick={handleClickActivateRabot} className="claim-button">
            {isActivatingRabot ? (
              <div>
                <Loading className="spinner" /> Activating Rabot
              </div>
            ) : (
              'Activate Rabot'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
