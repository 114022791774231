/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';
import { getActions, getGlobal, setGlobal } from '../../../../../global';

import '../../QuestDetails.scss';

import claimStep1 from '../../../../images/earn/quests/monad/monad-claim-step-1.png';
import monadJoinBanner from '../../../../images/earn/quests/monad/monad-join-banner-image.png';
import { useEffect, useState } from '../../../../../lib/teact/teact';
import { QuestTaskTypeEnum, QuestTypeEnum } from '../../../../apis/enums';
import {
  addChats,
  updateGlobalSearchFetchingStatus,
} from '../../../../../global/reducers';
import { buildCollectionByKey } from '../../../../../util/iteratees';
import {
  selectChat,
  selectCurrentGlobalSearchQuery,
} from '../../../../../global/selectors';
import { callApi } from '../../../../../api/gramjs';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { useStore } from '../../../../teact-redux/useStore';
import { useDispatch } from '../../../../teact-redux/useDispatch';
import EarnActions from '../../../../teact-redux/actions/earn';
import { MonadQuestStart } from './StartQuest';

export const MonadQuestTaskJoinTelegramGroup = () => {
  const [isVerifyGroupLoading, setIsVerifyGroupLoading] = useState(false);
  const [isStartQuestLoading, setIsStartQuestLoading] = useState(false);
  const [showNotVerifiedError, setShowNotVerifiedError] = useState(false);
  const userQuest = useStore((state) => state.earn.userQuest);

  const dispatch = useDispatch();

  const earnAction = new EarnActions(dispatch);
  const questNotStarted = !userQuest;

  const handleStartQuest = () => {
    setIsStartQuestLoading(true);
    earnAction.createUserQuest({
      questType: QuestTypeEnum.MONAD,
    });
    setIsStartQuestLoading(false);
  };

  const rabbleUpdatesChannelUsername = 'rabbleupdates';
  const rabbleUpdatesChannelId = '-1002414412616';

  const handleJoinMonadadGroup = async () => {
    setShowNotVerifiedError(false);

    const { openChat } = getActions();

    let global = getGlobal();

    const monad = selectChat(global, rabbleUpdatesChannelId);

    const query = rabbleUpdatesChannelUsername;

    const result = await callApi('searchChats', { query });

    global = getGlobal();
    const currentSearchQuery = selectCurrentGlobalSearchQuery(global);
    if (!result) {
      global = updateGlobalSearchFetchingStatus(global, { chats: false });
      setGlobal(global);
      return;
    }

    const {
      accountResultIds, globalResultIds, users, chats,
    } = result;

    global = addChats(global, buildCollectionByKey(chats, 'id'));

    const newMonad = selectChat(global, rabbleUpdatesChannelId);

    setGlobal(global);
    openChat({ id: rabbleUpdatesChannelId, shouldReplaceHistory: true });
  };

  const checkIfUserInMonadGroup = async () => {
    setShowNotVerifiedError(false);
    setIsVerifyGroupLoading(true);
    let global = getGlobal();

    const { currentUserId } = global;

    const monad = selectChat(global, rabbleUpdatesChannelId);

    const query = rabbleUpdatesChannelUsername;

    const result = await callApi('searchChats', { query });

    global = getGlobal();
    const currentSearchQuery = selectCurrentGlobalSearchQuery(global);
    if (!result) {
      global = updateGlobalSearchFetchingStatus(global, { chats: false });
      setGlobal(global);
      return;
    }

    const {
      accountResultIds, globalResultIds, users, chats,
    } = result;

    global = addChats(global, buildCollectionByKey(chats, 'id'));
    setGlobal(global);

    const newMonad = selectChat(global, rabbleUpdatesChannelId);

    const isMemberOfMonad = !!newMonad && !newMonad?.isNotJoined;

    if (isMemberOfMonad) {
      earnAction.updateUserQuestCompletedStep({
        questId: userQuest?.id!,
        taskType: QuestTaskTypeEnum.MONAD_JOIN_TELEGRAM,
        questType: QuestTypeEnum.MONAD,
      });
      earnAction.completedJoinGroupInMonadQuest();
    } else {
      setShowNotVerifiedError(true);
    }
    setIsVerifyGroupLoading(false);
  };

  useEffect(() => {
    if (!questNotStarted) {
      checkIfUserInMonadGroup();
    }
  }, []);

  if (questNotStarted) {
    return <MonadQuestStart />;
  }

  return (
    <div className="monad-quest-container monad">
      <div className="rewards__bottom-content monad">
        <div className="how-to-claim monad-rewards">
          <h3 className="title">How to Claim</h3>
          <div className="claim-process">
            <HowToClaimArrow />
            <div className="steps">
              <div className="first-step active-step">
                <HowToClaim1 className="icon" />
                <p className="step-text">Join the Rabble Telegram group</p>
              </div>
              <div className="third-step">
                <HowToClaim3 className="icon" />
                <p className="step-text">Agent Swaps For You</p>
              </div>
              <div className="second-step">
                <HowToClaim2 className="icon" />
                <p className="step-text">Invite 2 Friends To Rabble</p>
              </div>
            </div>
          </div>
        </div>

        <div className="claim-card">
          <h3 className="title">Claim Now</h3>
          <p className="sub-title">0/3 Steps Completed</p>
          <div className="claim-step-img">
            <img src={claimStep1} alt="" />
          </div>
          <p className="claim-text">
            Join the Telegram Group and come back here to complete this step and
            receive 0.1 $MON
          </p>
          <button onClick={handleJoinMonadadGroup} className="claim-button">
            {isVerifyGroupLoading ? 'Loading...' : 'Join Group'}
          </button>
        </div>
      </div>

      <div className="monad-rewards images-container">
        <h3 className="title">1. Join Rabble Telegram Group</h3>
        <img src={monadJoinBanner} alt="" />
      </div>
    </div>
  );
};
