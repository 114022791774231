/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { TGetEarnStatsResDto, TGetUserQuestByQuestTypeResDto } from '../../apis/dtos';
import type { Quest } from '../../earn/Earn';

export type TEarnState = {
  stats?: TGetEarnStatsResDto;
  userQuest?: TGetUserQuestByQuestTypeResDto;
  isDialogsUploaded: boolean;
  isEarnStatsStartedCalculating: boolean;
  dialogs: any;
  appVisitCount?: number;
  inviteAcceptedCount?: {
    monthlyInviteAcceptedCount: number;
    totalInviteAcceptedCount: number;
  };
  selectedQuest?: Quest | null;
  selectedQuestId?: string | null;
};

// Initial state
const initialState: TEarnState = {
  stats: undefined,
  userQuest: undefined,
  isDialogsUploaded: false,
  isEarnStatsStartedCalculating: false,
  dialogs: null,
  appVisitCount: undefined,
  inviteAcceptedCount: undefined,
  selectedQuest: null,
  selectedQuestId: null,
};

// Create Redux slice
const earnSlice = createSlice({
  name: 'EARN',
  initialState,
  reducers: {
    setEarnStats: (state, action: PayloadAction<{ stats: TGetEarnStatsResDto }>) => {
      state.stats = action.payload.stats;
    },
    setUserQuest: (state, action: PayloadAction<{ userQuest: TGetUserQuestByQuestTypeResDto }>) => {
      state.userQuest = action.payload.userQuest;
    },
    setDialogsUploaded: (state, action: PayloadAction<{ isDialogsUploaded: boolean }>) => {
      state.isDialogsUploaded = action.payload.isDialogsUploaded;
    },
    setIsEarnStatsStartedCalculating: (state, action: PayloadAction<{ isEarnStatsStartedCalculating: boolean }>) => {
      state.isEarnStatsStartedCalculating = action.payload.isEarnStatsStartedCalculating;
    },
    setDialogs: (state, action: PayloadAction<{ dialogs: any }>) => {
      state.dialogs = action.payload.dialogs;
    },
    setAppVisitCount: (state, action: { payload: { appVisitCount: number } }) => {
      state.appVisitCount = action.payload.appVisitCount;
    },
    setInviteAcceptedCount: (state, action: { payload: { inviteAcceptedCount: { monthlyInviteAcceptedCount: number; totalInviteAcceptedCount: number } } }) => {
      state.inviteAcceptedCount = action.payload.inviteAcceptedCount;
    },
    setSelectedQuest: (state, action: { payload: { selectedQuest: Quest | null } }) => {
      state.selectedQuest = action.payload.selectedQuest;
    },
    setSelectedQuestId: (state, action: { payload: { selectedQuestId: string | null } }) => {
      state.selectedQuestId = action.payload.selectedQuestId;
    },
  },
});

export const earnActions = earnSlice.actions;
export default earnSlice.reducer;
