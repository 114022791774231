import { getEncryptedWallets } from './get-encrypted-wallets';

type TSaveWalletsInBrowserStorageProps = {
  web3AuthWallets: {
    eth: { address: string; privateKey: string };
    sol: { address: string; privateKey: string };
    aptos: { address: string; privateKey: string };
    sui: { address: string; privateKey: string };
    ton: { address: string; privateKey: string };
  };
};

export const saveWalletsInBrowserStorage = ({ web3AuthWallets }: TSaveWalletsInBrowserStorageProps) => {
  const {
    encryptedEthPrivateKey,
    encryptedSolPrivateKey,
    encryptedAptosPrivateKey,
    encryptedSuiPrivateKey,
    encryptedTonPrivateKey,
  } = getEncryptedWallets({
    web3AuthEthWalletPrivateKey: web3AuthWallets.eth.privateKey,
    web3AuthSolWalletPrivateKey: web3AuthWallets.sol.privateKey,
    web3AuthAptosWalletPrivateKey: web3AuthWallets.aptos.privateKey,
    web3AuthSuiWalletPrivateKey: web3AuthWallets.sui.privateKey,
    web3AuthTonWalletPrivateKey: web3AuthWallets.ton.privateKey,
  });

  // Store encrypted private keys in session storage only if they are not already set
  if (!sessionStorage.getItem('encryptedEthPrivateKey')) {
    sessionStorage.setItem('encryptedEthPrivateKey', encryptedEthPrivateKey);
  }
  if (!sessionStorage.getItem('encryptedSolPrivateKey')) {
    sessionStorage.setItem('encryptedSolPrivateKey', encryptedSolPrivateKey);
  }
  if (!sessionStorage.getItem('encryptedAptosPrivateKey')) {
    sessionStorage.setItem('encryptedAptosPrivateKey', encryptedAptosPrivateKey);
  }
  if (!sessionStorage.getItem('encryptedSuiPrivateKey')) {
    sessionStorage.setItem('encryptedSuiPrivateKey', encryptedSuiPrivateKey);
  }
  if (!sessionStorage.getItem('encryptedTonPrivateKey')) {
    sessionStorage.setItem('encryptedTonPrivateKey', encryptedTonPrivateKey);
  }

  // Store wallet addresses in local storage only if they are not already set
  if (!localStorage.getItem('ethWalletAddress')) {
    localStorage.setItem('ethWalletAddress', web3AuthWallets.eth.address);
  }
  if (!localStorage.getItem('solWalletAddress')) {
    localStorage.setItem('solWalletAddress', web3AuthWallets.sol.address);
  }
  if (!localStorage.getItem('aptosWalletAddress')) {
    localStorage.setItem('aptosWalletAddress', web3AuthWallets.aptos.address);
  }
  if (!localStorage.getItem('suiWalletAddress')) {
    localStorage.setItem('suiWalletAddress', web3AuthWallets.sui.address);
  }
  if (!localStorage.getItem('tonWalletAddress')) {
    localStorage.setItem('tonWalletAddress', web3AuthWallets.ton.address);
  }
};
