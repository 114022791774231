import { createSlice } from '@reduxjs/toolkit';

type QuoteResult = any;

type TWalletSwapState = {
  sourceChain: number | null;
  destinationChain: number | null;
  sourceToken: string | null;
  destinationToken: string | null;
  amount: number;
  quote: QuoteResult | null;
  isGettingQuote: boolean;
  isSendingTransaction: boolean;
  quoteError: string | null;
  showConfirmSwapScreen: boolean;
  transactionHash: string | null;
  swapTransactionError: string | null;
};

// Initial state
const initialState: TWalletSwapState = {
  sourceChain: null,
  destinationChain: null,
  sourceToken: null,
  destinationToken: null,
  amount: 0,
  quote: null,
  isGettingQuote: false,
  isSendingTransaction: false,
  quoteError: null,
  showConfirmSwapScreen: false,
  transactionHash: null,
  swapTransactionError: null,
};

// Create Redux slice
const walletSwapSlice = createSlice({
  name: 'WALLET_SWAP',
  initialState,
  reducers: {
    setSourceChain: (state, action: { payload: number }) => {
      state.sourceChain = action.payload;
    },
    setDestinationChain: (state, action: { payload: number | null }) => {
      state.destinationChain = action.payload;
    },
    setSourceToken: (state, action: { payload: string }) => {
      state.sourceToken = action.payload;
    },
    setDestinationToken: (state, action: { payload: string }) => {
      state.destinationToken = action.payload;
    },
    setAmount: (state, action: { payload: number }) => {
      state.amount = action.payload;
    },
    setQuote: (state, action: { payload: QuoteResult | null }) => {
      state.quote = action.payload;
    },
    setLoading: (state, action: { payload: boolean }) => {
      state.isGettingQuote = action.payload;
    },
    setToInitialState: (state) => {
      state.sourceChain = initialState.sourceChain;
      state.destinationChain = initialState.destinationChain;
      state.sourceToken = initialState.sourceToken;
      state.destinationToken = initialState.destinationToken;
      state.amount = initialState.amount;
      state.quote = initialState.quote;
      state.isGettingQuote = initialState.isGettingQuote;
      state.isSendingTransaction = initialState.isSendingTransaction;
      state.quoteError = initialState.quoteError;
      state.showConfirmSwapScreen = initialState.showConfirmSwapScreen;
      state.transactionHash = initialState.transactionHash;
      state.swapTransactionError = initialState.swapTransactionError;
    },
    setIsSendingTransaction: (state, action: { payload: boolean }) => {
      state.isSendingTransaction = action.payload;
    },
    setShowConfirmSwapScreen: (state, action: { payload: boolean }) => {
      state.showConfirmSwapScreen = action.payload;
    },
    setQuoteError: (state, action: { payload: string | null }) => {
      state.quoteError = action.payload;
    },
    setTransactionHash: (state, action: { payload: string | null }) => {
      state.transactionHash = action.payload;
    },
    setSwapTransactionError: (state, action: { payload: string | null }) => {
      state.swapTransactionError = action.payload;
    },
  },
});

export const walletSwapActions = walletSwapSlice.actions;
export default walletSwapSlice.reducer;
