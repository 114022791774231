/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { TListUserNotificationsResDto, TListUserUnreadNotificationsResDto } from '../../apis/dtos/user-notification.dto';
import type { TListProductNotificationsResDto, TListProductUnreadNotificationsResDto } from '../../apis/dtos/product-notification.dto';

export type TNotificationState = {
  userNotifications: TListUserNotificationsResDto | undefined;
  unreadUserNotifications: TListUserUnreadNotificationsResDto | undefined;
  productNotifications: TListProductNotificationsResDto | undefined;
  unreadProductNotifications: TListProductUnreadNotificationsResDto | undefined;
};

// Initial state
const initialState: TNotificationState = {
  userNotifications: undefined,
  unreadUserNotifications: undefined,
  productNotifications: undefined,
  unreadProductNotifications: undefined,

};

// Create Redux slice
const notificationsSlice = createSlice({
  name: 'NOTIFICATIONS',
  initialState,
  reducers: {
    setUserNotifications: (state, action: PayloadAction<{ userNotifications: TListUserNotificationsResDto }>) => {
      state.userNotifications = action.payload.userNotifications;
    },
    setUnreadUserNotifications: (state, action: PayloadAction<{ userUnreadNotifications: TListUserUnreadNotificationsResDto }>) => {
      state.unreadUserNotifications = action.payload.userUnreadNotifications;
    },
    setProductNotifications: (state, action: PayloadAction<{ productNotifications: TListProductNotificationsResDto }>) => {
      state.productNotifications = action.payload.productNotifications;
    },
    setUnreadProductNotifications: (state, action: PayloadAction<{ unreadProductNotifications: TListProductUnreadNotificationsResDto }>) => {
      state.unreadProductNotifications = action.payload.unreadProductNotifications;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice.reducer;
