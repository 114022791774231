/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';
import {
  getActions,
  getGlobal,
  setGlobal,
  withGlobal,
} from '../../../../../global';

import '../../QuestDetails.scss';

import taskCompletedBanner from '../../../../images/earn/quests/fastlane/fastlane-completed-banner.png';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { CheckIcon2 } from '../../../../../components/common/icons/CheckIcon2';

export const FastlaneQuestAllTasksCompleted = () => {
  return (
    <div className="monad-quest-container monad">
      <div className="monad-rewards images-container">
        <h3 className="title">Quest Completed</h3>
        <img src={taskCompletedBanner} alt="" />
      </div>
      <div className="rewards__bottom-content monad">
        <div className="how-to-claim monad-rewards">
          <h3 className="title">How to Claim</h3>
          <div className="claim-process">
            <HowToClaimArrow />
            <div className="steps">
              <div className="third-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim3 className="icon" />
                <p className="step-text">Stake and LP in one click</p>
              </div>
              <div className="first-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim1 className="icon" />
                <p className="step-text">Join the Rabble Telegram group</p>
              </div>
              <div className="second-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim2 className="icon" />
                <p className="step-text">Invite 2 Friends To Rabble</p>
              </div>
            </div>
          </div>
        </div>
        <div className="claim-card">
          <h3 className="title">Claim Now</h3>
          <p className="sub-title">3/3 Steps Completed</p>
          <div className="reward-earned-container">
            <h3 className="title">Reward earned</h3>
            <p className="sub-title">
              See all your rewards in the Rabble Wallet and join Rabble Updates
              channel to stay in the loop.{' '}
            </p>
            <p className="reward-amount">
              {process.env.FASTLANE_QUEST_COMPLETION_MON_REWARD} $MON
              <br />
              <p className="reward-earned-footer-text">
                ! Follow @0xrabble on twitter for updates!
              </p>
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};
