/* eslint-disable import/no-cycle */
import type { Dispatch } from '@reduxjs/toolkit';
import pluto from '../../apis/pluto';
import { earnActions } from '../features/earn-slice';
import type { QuestTypeEnum } from '../../apis/enums';
import type { TActivateFastlaneMonadQuestResDto, TGetEarnStatsResDto, TInsertUserQuestTaskReqDto } from '../../apis/dtos';
import type { Quest } from '../../earn/Earn';

interface FetchUserQuestByQuestTypePayload {
  questType: QuestTypeEnum;
}

interface CreateUserQuestPayload {
  questType: QuestTypeEnum;
}

interface SetAppVisitCountPayload {
  appVisitCount: number;
}

interface SetInviteAcceptedCountPayload {
  monthlyInviteAcceptedCount: number;
  totalInviteAcceptedCount: number;
}

class EarnActions {
  private dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  async getUserEarnStats(): Promise<TGetEarnStatsResDto> {
    const stats = await pluto.services.earn.getUserEarnStats();
    this.dispatch(earnActions.setEarnStats({ stats }));
    return stats;
  }

  async fetchUserQuestByQuestType(payload: FetchUserQuestByQuestTypePayload): Promise<void> {
    const userQuest = await pluto.services.earn.fetchUserQuestByQuestType({ type: payload.questType });
    this.dispatch(earnActions.setUserQuest({ userQuest }));
  }

  async createUserQuest(payload: CreateUserQuestPayload): Promise<void> {
    const userQuest = await pluto.services.earn.createUserQuest({ questType: payload.questType });
    this.dispatch(earnActions.setUserQuest({ userQuest }));
  }

  async updateUserQuestCompletedStep(payload: TInsertUserQuestTaskReqDto): Promise<void> {
    await pluto.services.earn.updateUserQuestCompletedStep({
      questId: payload.questId,
      taskType: payload.taskType,
    });

    const userQuest = await pluto.services.earn.fetchUserQuestByQuestType({
      type: payload.questType!,
    });

    this.dispatch(earnActions.setUserQuest({ userQuest }));
  }

  async completedJoinGroupInMonadQuest(): Promise<void> {
    await pluto.services.earn.completedJoinGroupInMonadQuest({});
  }

  async activateMonadRabot(): Promise<void> {
    await pluto.services.earn.activateMonadRabot({});
  }

  async activateFastlaneRabot(): Promise<TActivateFastlaneMonadQuestResDto> {
    const batchId = await pluto.services.earn.activateFastlaneRabot({});

    return batchId;
  }

  async getBatchIdForFastlaneRabot(): Promise<{ batchId: string }> {
    const batchId = await pluto.services.earn.getBatchIdForFastlaneRabot();

    return { batchId: batchId.batchId };
  }

  setAppVisitCount(payload: SetAppVisitCountPayload): void {
    this.dispatch(earnActions.setAppVisitCount({ appVisitCount: payload.appVisitCount }));
  }

  setInviteAcceptedCount(payload: SetInviteAcceptedCountPayload): void {
    this.dispatch(earnActions.setInviteAcceptedCount({
      inviteAcceptedCount: {
        monthlyInviteAcceptedCount: payload.monthlyInviteAcceptedCount,
        totalInviteAcceptedCount: payload.totalInviteAcceptedCount,
      },
    }));
  }

  setDialogsUploaded(isUploaded: boolean): void {
    this.dispatch(earnActions.setDialogsUploaded({ isDialogsUploaded: isUploaded }));
  }

  setIsEarnStatsStartedCalculating(isCalculating: boolean): void {
    this.dispatch(earnActions.setIsEarnStatsStartedCalculating({ isEarnStatsStartedCalculating: isCalculating }));
  }

  setDialogs(dialogs: any): void {
    this.dispatch(earnActions.setDialogs({ dialogs }));
  }

  setSelectedQuest(selectedQuest: Quest | null): void {
    this.dispatch(earnActions.setSelectedQuest({ selectedQuest }));
  }

  setSelectedQuestId(selectedQuestId: string | null): void {
    this.dispatch(earnActions.setSelectedQuestId({ selectedQuestId }));
  }
}

export default EarnActions;
