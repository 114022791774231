import { z } from 'zod';

export const TBlockchain = z.enum([
  'ethereum',
  'solana',
  'polygon',
  'arbitrum',
  'optimism',
  'base',
  'monad',
  'aptos',
  'sui',
  'ton',
]);

export const TTokenSymbol = z.enum([
  'USDC',
  'USDT',
  'ETH',
  'MATIC',
  'SOL',
  'OP',
  'APT',
  'SUI',
  'TON',
  'MON',
]);

export const TAssetTransformer = z.object({
  balance: z.string(),
  balanceUsd: z.string().nullable(),
  blockchain: TBlockchain,
  blockchainThumbnail: z.string().nullable(),
  contractAddress: z.string().nullable(),
  tokenThumbnail: z.string().nullable(),
  tokenName: z.string(),
  tokenPrice: z.string().nullable(),
  tokenSymbol: TTokenSymbol,
  tokenDecimals: z.number().nullable(),
  tokenType: z.enum(['ERC20', 'NATIVE']),
});

export const GetAllBalancesResTransformer = z.object({
  totalCount: z.number(),
  totalBalanceUsd: z.string(),
  totalBalanceEthUsd: z.string(),
  totalBalanceSolanaUsd: z.string(),
  totalBalanceTonUsd: z.string(),
  totalBalanceSuiUsd: z.string(),
  totalBalanceAptosUsd: z.string(),
  totalBalanceMonadUsd: z.string(),
  assets: z.array(TAssetTransformer),
});
