export enum TransactionTypeEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  SWAP = 'SWAP',
  APPROVE = 'APPROVE',
  APPROVE_LP_TOKEN = 'APPROVE_LP_TOKEN',
  LIQUIDITY_ADD = 'LIQUIDITY_ADD',
  LIQUIDITY_REMOVE = 'LIQUIDITY_REMOVE',
  SEND = 'SEND',
  SEND_P2P = 'SEND_P2P',
  RECEIVE = 'RECEIVE',
  RECEIVE_P2P = 'RECEIVE_P2P',
  RABOT_FUND = 'RABOT_FUND',
}

export enum RabotTransactionEnum {
  QUEUED = 'QUEUED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
