import type { Store } from '@reduxjs/toolkit';

class StoreHolder {
  private static store: Store | null = null;

  static setStore(store: Store) {
    this.store = store;
  }

  static getStore(): Store {
    if (!this.store) {
      throw new Error('Redux store has not been initialized!');
    }
    return this.store;
  }
}

export default StoreHolder;
