export enum BatchTransactionTypeEnum {
  FUND = 'FUND',
  WITHDRAW = 'WITHDRAW',
}

export enum BatchTransactionNetworkEnum {
  BASE = 'BASE',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
}

export enum BatchTransactionStatusEnum {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum BatchTransactionStageEnum {
  FUNDING_INITIATED = 'FUNDING_INITIATED',
  FUNDING_SUCCESS = 'FUNDING_SUCCESS',
  FUNDING_FAILED = 'FUNDING_FAILED',
  DEPLOYMENT_INITIATED = 'DEPLOYMENT_INITIATED',
  DEPLOYMENT_SUCCESS = 'DEPLOYMENT_SUCCESS',
  DEPLOYMENT_FAILED = 'DEPLOYMENT_FAILED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  REFUND_SUCCESS = 'REFUND_SUCCESS',
  REFUND_FAILED = 'REFUND_FAILED',
  REDEMPTION_INITIATED = 'REDEMPTION_INITIATED',
  REDEMPTION_SUCCESS = 'REDEMPTION_SUCCESS',
  REDEMPTION_FAILED = 'REDEMPTION_FAILED',
  WITHDRAWAL_INITIATED = 'WITHDRAWAL_INITIATED',
  WITHDRAWAL_SUCCESS = 'WITHDRAWAL_SUCCESS',
  WITHDRAWAL_FAILED = 'WITHDRAWAL_FAILED',
}
