/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../lib/teact/teactn';
import { getActions } from '../../../../global';

import useAppLayout from '../../../../hooks/useAppLayout';

import '../QuestDetails.scss';

import howToJoin from '../../../images/earn/howToJoin.png';
import useCopyToClipboard from '../../../utils/useCopyToClipboard';
import { CopyBorderedIcon } from '../../../components/icons/CopyBorderedIcon';
import { TopRightIcon } from '../../../components/icons/top-right';

export const RapidNodeQuestTask1 = () => {
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const { showNotification } = getActions();
  const { isMobile } = useAppLayout();

  function handleCodeCopyToClipboard(value: string) {
    copyToClipboard(value);
    showNotification({ message: 'Code Copied to Clipboard' });
  }

  return (
    <div className="rewards__bottom-content">
      <div className="how-to-claim">
        <h3 className="title">How to Claim</h3>
        <div className="sub-title">
          <TopRightIcon className="arrow-icon" />
          <a
            className="text"
            href="https://app.rapidnode.xyz/dashboard"
            target="_blank"
            rel="noreferrer"
          >
            Launch Rapid Node
          </a>
        </div>
        <div className="how-to-claim-img">
          <img src={howToJoin} alt="" />
        </div>
      </div>
      <div className="claim-card">
        <h3 className="title">Claim Now</h3>
        <p className="sub-title">IMPORTANT: This code can only be used once!</p>
        <div className="copy-code">
          <p className="code">ZIKS734XDFAAS</p>
          <CopyBorderedIcon
            className="copy-icon"
            onClick={() => handleCodeCopyToClipboard('ZIKS734XDFAAS')}
          />
        </div>
        <button className="coming-soon-btn">Coming Soon</button>
      </div>
    </div>
  );
};
