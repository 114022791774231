export enum RabotEnum {
  AERODROME_WETH_USDC = 'AERODROME_WETH_USDC',
  AERODROME_WETH_DEGEN = 'AERODROME_WETH_DEGEN',
  AERODROME_USDC_MAI = 'AERODROME_USDC_MAI',
  AERODROME_USDC_AERODROME = 'AERODROME_USDC_AERODROME',
  CURVE_CRVUSD_USDC = 'CURVE_CRVUSD_USDC',
  CURVE_CBETH_WETH = 'CURVE_CBETH_WETH',
  CURVE_CRVUSD_SUSD = 'CURVE_CRVUSD_SUSD',
  BIMA_EARN_MONAD_TESTNET = 'BIMA_EARN_MONAD_TESTNET',
  MORPHO_SPARK_USDC_VAULT_BASE = 'MORPHO_SPARK_USDC_VAULT_BASE',
  MORPHO_APOSTRO_USR_VAULT_BASE = 'MORPHO_APOSTRO_USR_VAULT_BASE',
  FASTLANE_EARN_MONAD_TESTNET = 'FASTLANE_EARN_MONAD_TESTNET',
  
}

export enum VaultEnum {
  BASIC_VAULT = 'BASIC_VAULT',
  PRO_VAULT = 'PRO_VAULT',
}
