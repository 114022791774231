import type { TListProductNotificationsResDto, TListProductUnreadNotificationsResDto, TMarkProductNotificationAsReadReqDto } from './dtos/product-notification.dto';
import type { HttpClient } from './http';

export class ProductNotificationService {
  constructor(private readonly httpClient: HttpClient) {}

  public listProductNotifications(page: number, size: number): Promise<TListProductNotificationsResDto> {
    return this.httpClient.get(`/product-notifications?page=${page}&size=${size}`);
  }

  public listUnreadProductNotifications(): Promise<TListProductUnreadNotificationsResDto> {
    return this.httpClient.get('/product-notifications/unread');
  }

  public markProductNotificationAsRead(body: TMarkProductNotificationAsReadReqDto): Promise<void> {
    return this.httpClient.post('/product-notifications/read', body);
  }
}
