/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';
import '../../QuestDetails.scss';

import claimStep0 from '../../../../images/earn/quests/monad/monad-claim-step-0.png';
import { useState } from '../../../../../lib/teact/teact';
import { QuestTypeEnum } from '../../../../apis/enums';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { useStore } from '../../../../teact-redux/useStore';
import { useDispatch } from '../../../../teact-redux/useDispatch';
import EarnActions from '../../../../teact-redux/actions/earn';

export const FastlaneQuestStart = () => {
  const [isVerifyGroupLoading, setIsVerifyGroupLoading] = useState(false);
  const [isStartQuestLoading, setIsStartQuestLoading] = useState(false);
  const [showNotVerifiedError, setShowNotVerifiedError] = useState(false);
  const userQuest = useStore((state) => state.earn.userQuest);

  const dispatch = useDispatch();

  const earnAction = new EarnActions(dispatch);
  const questNotStarted = !userQuest;

  const handleStartQuest = () => {
    setIsStartQuestLoading(true);
    earnAction.createUserQuest({
      questType: QuestTypeEnum.FASTLANE_MONAD,
    });
    setIsStartQuestLoading(false);
  };

  return (
    <div className="rewards__bottom-content monad">
      <div className="how-to-claim monad-rewards">
        <h3 className="title">How to Claim</h3>
        <div className="claim-process">
          <HowToClaimArrow />
          <div className="steps">
            <div className="first-step">
              <HowToClaim3 />
              <p className="step-text">Stake and LP in one click!</p>
            </div>
            <div className="second-step">
              <HowToClaim1 />
              <p className="step-text">Join the Rabble Telegram group</p>
            </div>
            <div className="third-step">
              <HowToClaim2 />
              <p className="step-text">Invite 2 Friends To Rabble</p>
            </div>
          </div>
        </div>
      </div>

      <div className="claim-card start-quest">
        <h3 className="title">Claim Now</h3>
        <p className="sub-title">Start Quest</p>
        <div className="claim-step-img">
          <img src={claimStep0} alt="" />
        </div>
        <p className="sub-title">Click the button below to start this quest</p>
        <button onClick={handleStartQuest} className="claim-button">
          {isStartQuestLoading ? 'Loading...' : 'Start Quest'}
        </button>
      </div>
    </div>
  );
};
