/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';

import '../../QuestDetails.scss';

import activateRabotBanner from '../../../../images/earn/quests/monad/monad-activate-rabot-banner.png';
import claimStep2 from '../../../../images/earn/quests/monad/monad-claim-step-2.png';
import { QuestTaskTypeEnum, QuestTypeEnum } from '../../../../apis/enums';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { useStore } from '../../../../teact-redux/useStore';
import { useDispatch } from '../../../../teact-redux/useDispatch';
import EarnActions from '../../../../teact-redux/actions/earn';
import { useState } from '../../../../../lib/teact/teact';
import { CheckIcon2 } from '../../../../../components/common/icons/CheckIcon2';
import { Loading } from '../../../../../components/common/icons/Loading';

export const MonadQuestTaskFundRabot = () => {
  const dispatch = useDispatch();

  const userQuest = useStore((state) => state.earn.userQuest);
  const earnAction = new EarnActions(dispatch);
  const [isActivatingRabot, setIsActivatingRabot] = useState(false);

  const handleClickAcitvateRabot = () => {
    try {
      setIsActivatingRabot(true);
      setTimeout(() => {
        earnAction.activateMonadRabot();
        earnAction.updateUserQuestCompletedStep({
          questId: userQuest?.id!,
          taskType: QuestTaskTypeEnum.MONAD_FUND_MONAD_RABOT,
          questType: QuestTypeEnum.MONAD,
        });
        setIsActivatingRabot(false);
      }, 3000);
    } catch (e) {
      //
    }
  };

  return (
    <div className="monad-quest-container monad">
      <div className="rewards__bottom-content monad">
        <div className="how-to-claim monad-rewards">
          <h3 className="title">How to Claim</h3>
          <div className="claim-process">
            <HowToClaimArrow />
            <div className="steps">
              <div className="first-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim1 className="icon" />
                <p className="step-text">Join the Rabble Telegram group</p>
              </div>
              <div className="third-step active-step">
                <HowToClaim3 className="icon" />
                <p className="step-text">Agent Swaps For You</p>
              </div>
              <div className="second-step">
                <HowToClaim2 className="icon" />
                <p className="step-text">Invite 2 Friends To Rabble</p>
              </div>
            </div>
          </div>
        </div>
        <div className="claim-card">
          <h3 className="title">Claim Now</h3>
          <p className="sub-title">1/3 Steps Completed</p>
          <div className="claim-step-img">
            <img src={claimStep2} alt="" />
          </div>
          <p className="sub-title">
            Our Rabot will fetch you some more $MON from the faucet and Swap it
            for $USDC (on Monad Testnet)
          </p>
          <button onClick={handleClickAcitvateRabot} className="claim-button">
            {isActivatingRabot ? (
              <div>
                <Loading className="spinner" /> Activating Rabot
              </div>
            ) : (
              'Activate Rabot'
            )}
          </button>
        </div>
      </div>
      <div className="monad-rewards images-container">
        <h3 className="title">2. Agent Swaps For You</h3>
        <img src={activateRabotBanner} alt="" />
      </div>
    </div>
  );
};
