import { useState, useCallback } from '../../lib/teact/teact';

const useCopyToClipboard = (): [boolean, (text: string) => void] => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(() => {
        setIsCopied(false);
      });
  }, []);

  return [isCopied, copyToClipboard];
};

export default useCopyToClipboard;
