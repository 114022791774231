/* eslint-disable max-len */
/* eslint-disable no-console */
import type { Dispatch } from 'redux';

import { userActions } from '../features/user-slice';
import type { TGetRabbleUserResDto } from '../../apis/dtos';

interface SetMeInfoPayload {
  me?: TGetRabbleUserResDto;
}

class UserActions {
  private dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  setMeInfo(payload: SetMeInfoPayload): void {
    this.dispatch(userActions.setMe({ me: payload.me }));
  }
}

export { UserActions };
