/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { set } from 'idb-keyval';
import type {
  TGetRaboDetailsResDto, TGetUserRabotsOverviewResDto, TGetVaultResDto, TListRabotsResDto,
} from '../../apis/dtos/rabots';
import { RabotScreens } from '../../../types';
import { setVariable } from '../../../util/theme';
import type { VaultEnum } from '../../apis/enums';

export type TRabotsState = {
  selectedRabotId?: string;
  selectedRabot: TGetRaboDetailsResDto | null;
  allRabots?: TListRabotsResDto;
  overview?: TGetUserRabotsOverviewResDto;
  fundBotSelectedBot: TGetRaboDetailsResDto | null;
  currentRabotScreen?: RabotScreens;
  batchId?: string;
  basicRabot?: TGetVaultResDto;
  proRabot?: TGetVaultResDto;
  selectedVault?: VaultEnum;
};

// Initial state
const initialState: TRabotsState = {
  selectedRabotId: '',
  selectedRabot: null,
  allRabots: [],
  overview: undefined,
  fundBotSelectedBot: null,
  currentRabotScreen: undefined,
  batchId: undefined,
  basicRabot: undefined,
  proRabot: undefined,
  selectedVault: undefined,

};

// Create Redux slice
const rabotsSlice = createSlice({
  name: 'RABOTS',
  initialState,
  reducers: {
    setSelectedRabotId: (state, action: PayloadAction<{ selectedRabotId: string }>) => {
      state.selectedRabotId = action.payload.selectedRabotId;
    },
    setRabotsToIntialState: (state) => {
      state.selectedRabotId = '';
      state.selectedRabot = null;
      state.fundBotSelectedBot = null;
      state.currentRabotScreen = RabotScreens.AllRabots;
      state.batchId = undefined;
    },
    setSelectedRabot: (state, action: PayloadAction<{ selectedRabot: TGetRaboDetailsResDto | null }>) => {
      state.selectedRabot = action.payload.selectedRabot;
    },
    setAllRabots: (state, action: PayloadAction<{ allRabots: TListRabotsResDto }>) => {
      state.allRabots = action.payload.allRabots;
    },
    setOverview: (state, action: PayloadAction<{ overview: TGetUserRabotsOverviewResDto }>) => {
      state.overview = action.payload.overview;
    },
    setFundBotSelectedBot: (state, action: PayloadAction<{ fundBotSelectedBot: TGetRaboDetailsResDto | null }>) => {
      state.fundBotSelectedBot = action.payload.fundBotSelectedBot;
    },
    setCurrentRabotScreen: (state, action: PayloadAction<{ currentRabotScreen?: RabotScreens }>) => {
      state.currentRabotScreen = action.payload.currentRabotScreen;
    },
    setBatchId: (state, action: PayloadAction<{ batchId: string | undefined }>) => {
      state.batchId = action.payload.batchId;
    },
    setBasicRabot: (state, action: PayloadAction<{ basicRabot: TGetVaultResDto | undefined }>) => {
      state.basicRabot = action.payload.basicRabot;
    },
    setProRabot: (state, action: PayloadAction<{ proRabot: TGetVaultResDto | undefined }>) => {
      state.proRabot = action.payload.proRabot;
    },
    setSelectedVault: (state, action: PayloadAction<{ selectedVault: VaultEnum }>) => {
      state.selectedVault = action.payload.selectedVault;
    },
  },
});

export const rabotsActions = rabotsSlice.actions;
export default rabotsSlice.reducer;
