import { Web3Auth } from '@web3auth/single-factor-auth';
import {
  EvmPrivateKeyProvider, getAptosAddress, getEthereumAddress, getSolanaAddress, getTonAddress,
  getSuiAddress,
} from '../providers';
import { generateJwtToken } from './generate-jwt-token';
import { saveWalletsInBrowserStorage } from './save-wallet-in-broswer-storage';

export async function web3AuthConnect(telegramUserId: string) {
  const clientId = process.env.WEB3_AUTH_CLIENT_ID!;

  const web3auth = new Web3Auth({
    clientId,
    web3AuthNetwork: 'mainnet',
    privateKeyProvider: EvmPrivateKeyProvider,
  });

  await web3auth.init();

  const {
    provider,
  } = web3auth;

  const token = await generateJwtToken(telegramUserId);

  let initProvider = provider;

  if (!web3auth.connected) {
    try {
      initProvider = await web3auth.connect({
        verifier: 'rabble-legacy-mainnet-verifier',
        verifierId: telegramUserId,
        idToken: token!,
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  let ethWalletAddress;
  let ethPrivateKey;
  let solAddress;
  let solPrivateKey;
  let suiAddress;
  let suiPrivateKey;
  let aptosAddress;
  let aptosPrivateKey;
  let tonAddress;
  let tonPrivateKey;

  if (initProvider) {
    // Ethereum wallet
    ({ ethWalletAddress, ethPrivateKey } = await getEthereumAddress(initProvider));

    // Solana wallet
    ({ solAddress, solPrivateKey } = await getSolanaAddress(ethPrivateKey as string));

    // Aptos wallet
    ({ aptosAddress, aptosPrivateKey } = await getAptosAddress(ethPrivateKey as string));

    // Sui Wallet
    ({ suiAddress, suiPrivateKey } = await getSuiAddress(ethPrivateKey as string));

    // Ton Wallet
    ({ tonAddress, tonPrivateKey } = await getTonAddress(ethPrivateKey as string));
  }

  // Save wallets in browser storage
  saveWalletsInBrowserStorage({
    web3AuthWallets: {
      eth: {
        address: ethWalletAddress!,
        privateKey: ethPrivateKey!,
      },
      sol: {
        address: solAddress!,
        privateKey: solPrivateKey!,
      },
      aptos: {
        address: aptosAddress!,
        privateKey: aptosPrivateKey!,
      },
      sui: {
        address: suiAddress!,
        privateKey: suiPrivateKey!,
      },
      ton: {
        address: tonAddress!,
        privateKey: tonPrivateKey!,
      },
    },
  });

  return {
    ethWalletAddress,
    ethPrivateKey,
    solPrivateKey,
    solAddress,
    aptosAddress,
    aptosPrivateKey,
    suiAddress,
    suiPrivateKey,
    tonAddress,
    tonPrivateKey,
  };
}
