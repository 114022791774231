import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type TRabbleRefature = 'earn' | 'wallet' | 'rabot' | 'feed' | 'rabble' | 'notifications' | null;

enum MiddleScreen {
  Feed,
  Earn,
  Rabot,
  Notifications,
}

export type TLayoutState = {
  activeRabbleFeature: TRabbleRefature;
  emailVerificationKey?: string;
  currentMiddleScreen?: MiddleScreen;
  isWalletOpen?: boolean;
  isFeedOpen?: boolean;
  isRabbleInviteUsersOpen?: boolean;
  isEarnOpen?: boolean;
  isRabotsOpen?: boolean;
  isDisclaimerModalOpen?: boolean;
};

// Initial state
const initialState: TLayoutState = {
  activeRabbleFeature: null,
  emailVerificationKey: '',
  currentMiddleScreen: undefined,
  isWalletOpen: false,
  isFeedOpen: false,
  isRabbleInviteUsersOpen: false,
  isEarnOpen: false,
  isRabotsOpen: false,
  isDisclaimerModalOpen: true,
};

// Create Redux slice
const layoutSlice = createSlice({
  name: 'LAYOUT',
  initialState,
  reducers: {
    setActiveRabbleFeature: (state, action: PayloadAction<TRabbleRefature>) => {
      state.activeRabbleFeature = action.payload;
    },
    setEmailVerificationKey: (state, action: PayloadAction<{ emailVerificationKey: string }>) => {
      state.emailVerificationKey = action.payload.emailVerificationKey;
    },
    setCurrentMiddleScreen: (state, action: PayloadAction<{ currentScreen?: MiddleScreen }>) => {
      state.currentMiddleScreen = action.payload.currentScreen;
    },
    setIsWalletOpen: (state, action: PayloadAction<{ isWalletOpen: boolean }>) => {
      state.isWalletOpen = action.payload.isWalletOpen;

      if (state.isWalletOpen) {
        state.activeRabbleFeature = 'wallet';
      } else {
        state.activeRabbleFeature = null;
      }
    },
    setIsFeedOpen: (state, action: PayloadAction<{ isFeedOpen: boolean }>) => {
      state.isFeedOpen = action.payload.isFeedOpen;
    },
    setIsRabbleInviteUsersOpen: (state, action: PayloadAction<{ isRabbleInviteUsersOpen: boolean }>) => {
      state.isRabbleInviteUsersOpen = action.payload.isRabbleInviteUsersOpen;
    },
    setIsEarnOpen: (state, action: PayloadAction<{ isEarnOpen: boolean }>) => {
      state.isEarnOpen = action.payload.isEarnOpen;
    },
    setIsRabotsOpen: (state, action: PayloadAction<{ isRabotsOpen: boolean }>) => {
      state.isRabotsOpen = action.payload.isRabotsOpen;
    },
    setIsDisclaimerModalOpen: (state, action: PayloadAction<{ isDisclaimerModalOpen: boolean }>) => {
      state.isDisclaimerModalOpen = action.payload.isDisclaimerModalOpen;
    },
  },
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice.reducer;
