import React from '../../lib/teact/teact';
import { layoutActions } from '../teact-redux/features/layout-slice';
import { useDispatch } from '../teact-redux/useDispatch';
import { useStore } from '../teact-redux/useStore';

import './DisclaimerModal.scss';

const DisclaimerModal = () => {
  const isDisclaimerModalOpen = useStore((state) => state.layout.isDisclaimerModalOpen);
  const dispatch = useDispatch();

  if (!isDisclaimerModalOpen) {
    return null;
  }

  const closeDisclaimerModal = () => {
    dispatch(layoutActions.setIsDisclaimerModalOpen({ isDisclaimerModalOpen: false }));
  };

  return (
    <div className={`disclaimer-overlay ${isDisclaimerModalOpen ? 'open' : ''}`}>
      <div className="disclaimer-modal">
        <p className="title">Disclaimer:</p>
        <p className="description">
          This is a telegram client. By logging in, you will sync your telegram chats, groups and channels onto rabble.
          <br />
          <br />
          Rabble strictly adheres to Telegram’s privacy policy and does not access any of your private information on telegram.
          <br />
          <br />

          No one from rabble or anything on the client will ever ask you for your private information.
          <br />
          <br />

          Be sure to back up your wallet inside rabble. This way, you can still access your funds on rabble even if you lose access to telegram.
        </p>
        <div className="btn-container">
          <a href="https://www.rabble.pro/tos" target="_blank" className="terms-of-service-link" rel="noreferrer">Read terms of service</a>
          <button onClick={closeDisclaimerModal} className="accept-btn">
            Accept
          </button>
        </div>
      </div>
    </div>

  );
};

export default DisclaimerModal;
