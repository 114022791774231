import { z } from 'zod';

import {
  BatchTransactionNetworkEnum,
  BatchTransactionStatusEnum,
  BatchTransactionTypeEnum,
  DappEnum, RabotEnum,
  RabotNetworkEnum, RabotStrategyTypeEnum,
} from '../../enums';

export const RabotResTransformer = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(RabotEnum),
  dappType: z.nativeEnum(DappEnum),
  strategyType: z.nativeEnum(RabotStrategyTypeEnum).nullable(),
  network: z.nativeEnum(RabotNetworkEnum),
  description: z.string().nullable(),
  rabotDescription: z.string().nullable(),
  risks: z.string().nullable(),
  logo: z.string().nullable(),
  cta: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  apy: z.number().nullable().optional(),
  tvl: z.string().nullable().optional(),
});

const RabotFullDetailsTransformer = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  rabotDescription: z.string().nullable(),
  risks: z.string().nullable(),
  logo: z.string().nullable(),
  cta: z.string().nullable(),
  type: z.nativeEnum(RabotEnum),
  dappType: z.nativeEnum(DappEnum),
  strategyType: z.nativeEnum(RabotStrategyTypeEnum).nullable(),
  network: z.string().nullable(),
  userBotId: z.string().nullable(),
  userBotWalletAddress: z.string().nullable(),
  userBotSmartWalletAddress: z.string().nullable(),
  userBotDepositedAmount: z.number().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const CreateRabotReqTransformer = z.object({
  name: z.string(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  cta: z.string().optional().nullable(),
  network: z.string().optional().nullable(),
});

export const ListRabotsResTransformer = z.array(RabotResTransformer);

export const GetRabotResTransformer = RabotFullDetailsTransformer;

export const GetUserRabotsOverviewResTransformer = z.object({
  totalDepositedAmount: z.coerce.number().nullable(),
  totalActiveRabots: z.coerce.number().nullable(),
  totalInactiveRabots: z.coerce.number().nullable(),
});

export const CreateBatchReqTransformer = z.object({
  userBotId: z.string(),
  type: z.nativeEnum(BatchTransactionTypeEnum),
  amount: z.number(),
  txHash: z.string(),
  actionType: z.string(),
  fromAddress: z.string(),
  toAddress: z.string(),
  gas: z.number(),
  network: z.nativeEnum(BatchTransactionNetworkEnum),
  currency: z.string().optional(),
});
export const CreateBatchResTransformer = z.object({
  id: z.string(),
});

export const GetBatchStatusResTransformer = z.object({
  status: z.nativeEnum(
    BatchTransactionStatusEnum,
  ),
});

export const CreateWithdrawBatchTransactionReqTransformer = z.object({
  userBotId: z.string(),
  type: z.nativeEnum(BatchTransactionTypeEnum),
  amountInPercentage: z.number(),
  currency: z.string().optional(),
});

export const CreateWithdrawBatchTransactionResTransformer = z.object({
  id: z.string(),
});

const VaultFullDetailsTransformer = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  rabotDescription: z.string().nullable(),
  risks: z.string().nullable(),
  logo: z.string().nullable(),
  cta: z.string().nullable(),
  type: z.nativeEnum(RabotEnum),
  dappType: z.nativeEnum(DappEnum),
  strategyType: z.nativeEnum(RabotStrategyTypeEnum).nullable(),
  network: z.nativeEnum(RabotNetworkEnum),
  userBotId: z.string().nullable(),
  userBotWalletAddress: z.string().nullable(),
  userBotSmartWalletAddress: z.string().nullable(),
  userBotDepositedAmount: z.number().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
export const GetVaultResTransformer = VaultFullDetailsTransformer;
