import { z } from 'zod';
import { ProductNotificationActionTypeEnum, ProductNotificationTypeEnum } from '../enums';

export const ProductNotificationsResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(ProductNotificationTypeEnum),
  title: z.string(),
  ctaTitle: z.string(),
  content: z.string(),
  actionType: z.nativeEnum(ProductNotificationActionTypeEnum),
  externalLink: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  image: z.string().nullable(),
})

export const ListProductNotificationsResTransformer = z.array(ProductNotificationsResTransformer);

export const ListProductUnreadNotificationsResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(ProductNotificationTypeEnum),
  title: z.string(),
  content: z.string(),
  actionType: z.nativeEnum(ProductNotificationActionTypeEnum),
  externalLink: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
}).array();

export const MarkProductNotificationAsReadReqTransformer = z.object({
  notificationIds: z.string().array(),
});
