/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import type { Quest } from '../Earn';

import mintAirHeader from '../../images/earn/mint-air-header.png';
import mintAirChain1 from '../../images/earn/mintAirChains/chain-1.png';
import mintAirChain2 from '../../images/earn/mintAirChains/chain-2.png';
import mintAirChain3 from '../../images/earn/mintAirChains/chain-3.png';
import mintAirChain4 from '../../images/earn/mintAirChains/chain-4.png';
import mintAirChain5 from '../../images/earn/mintAirChains/chain-5.png';
import rabbleEarnHeader from '../../images/earn/rabble-quest-header.png';
import monadEarnHeader from '../../images/earn/quests/monad/monad-quest-header.png';
import fastlaneEarnHeader from '../../images/earn/quests/fastlane/fastlane-quest-header.png';
import rapidNodeHeader from '../../images/earn/rapid-node-header.png';
import rapidNodeChain1 from '../../images/earn/rapidNodeChains/chain-1.png';
import rapidNodeChain2 from '../../images/earn/rapidNodeChains/chain-2.png';
import rapidNodeChain3 from '../../images/earn/rapidNodeChains/chain-3.png';
import rapidNodeChain4 from '../../images/earn/rapidNodeChains/chain-4.png';
import rapidNodeChain5 from '../../images/earn/rapidNodeChains/chain-5.png';
import { QuestTaskTypeEnum, QuestTypeEnum } from '../../apis/enums';
import { RapidNodeQuestTask1 } from './tasks/RapidNodeQuestTasks';

import { MintairQuestTask1 } from './tasks/MintairQuestTasks';
import './QuestDetails.scss';
import { MonadQuestTaskFundRabot } from './tasks/monad/ActivateRabot';
import { MonadQuestTaskJoinTelegramGroup } from './tasks/monad/JoinTelegramGroup';
import { MonadQuestTaskReferFriends } from './tasks/monad/ReferFriends';
import { MonadQuestAllTasksCompleted } from './tasks/monad/AllTasksCompleted';
import { FastlaneQuestTaskActivateRabot } from './tasks/fastlane/ActivateRabot';
import { FastlaneQuestTaskJoinTelegramGroup } from './tasks/fastlane/JoinTelegramGroup';
import { FastlaneQuestTaskReferFriends } from './tasks/fastlane/ReferFriends';
import { FastlaneQuestAllTasksCompleted } from './tasks/fastlane/AllTasksCompleted';
import FastlaneQuestDescriptionPoints from './tasks/fastlane/FastlaneQuestDescriptionPoints';

export const monadQuest = {
  id: '2',
  name: 'Monad',
  image: monadEarnHeader,
  tag: '$MON (Testnet)',
  rewardDetailsHeader: monadEarnHeader,
  shortDescription: 'Join the Monad Testnet, and earn $MON! Complete simple tasks, refer friends, and watch your earnings multiply. Completing this quest also puts you in a prime spot to earn even bigger rewards with Monad down the line!',
  description:
      'Join the Monad Testnet, and earn $MON! Complete simple tasks, refer friends, and watch your earnings multiply. Completing this quest also puts you in a prime spot to earn even bigger rewards with Monad down the line!',
  questType: QuestTypeEnum.MONAD,
  questsTasks: {
    [QuestTaskTypeEnum.MONAD_JOIN_TELEGRAM]: MonadQuestTaskJoinTelegramGroup,
    [QuestTaskTypeEnum.MONAD_FUND_MONAD_RABOT]: MonadQuestTaskFundRabot,
    [QuestTaskTypeEnum.MONAD_REFER_FRIEND]: MonadQuestTaskReferFriends,
  },
  allTaskCompletedComponent: MonadQuestAllTasksCompleted,
};

export const mintairQuest = {
  id: '3',
  name: 'MintAir',
  image: mintAirHeader,
  tag: 'Node Subscription',
  rewardDetailsHeader: mintAirHeader,
  description:
      'Claim your free node subscription and start earning actual rewards on your nodes! Get upto 3 months of Node Subscriptions worth upto $40 per month',
  availableChains: [
    mintAirChain1,
    mintAirChain2,
    mintAirChain3,
    mintAirChain4,
    mintAirChain5,
  ],
  questsTasks: {
    [QuestTaskTypeEnum.MINTAIR_COPY_CODE]: MintairQuestTask1,
  },
};

export const rapidNodeQuest = {
  id: '4',
  name: 'Rapid Node',
  image: rapidNodeHeader,
  tag: 'Node Subscription',
  rewardDetailsHeader: rapidNodeHeader,
  description:
      'Claim your free node subscription and start earning actual rewards on your nodes! Get upto 3 months of Node Subscriptions worth upto $50 per month',
  availableChains: [
    rapidNodeChain1,
    rapidNodeChain2,
    rapidNodeChain3,
    rapidNodeChain4,
    rapidNodeChain5,
  ],
  questsTasks: {
    [QuestTaskTypeEnum.RAPIDNODE_COPY_CODE]: RapidNodeQuestTask1,
  },
};

export const fastlaneQuest = {
  id: '3',
  name: 'shMonad Quest',
  subtitle: 'Win 5 $MON and Fastlane points in the future for referring your friends to stake shMon',
  image: fastlaneEarnHeader,
  tag: 'Rewards: Fastlane points',
  rewardDetailsHeader: fastlaneEarnHeader,
  shortDescription: 'Win 5 $MON and Fastlane points in the future for referring your friends to stake shMon',
  questType: QuestTypeEnum.FASTLANE_MONAD,
  questsTasks: {
    [QuestTaskTypeEnum.FASTLANE_ACTIVATE_RABOT]: FastlaneQuestTaskActivateRabot,
    [QuestTaskTypeEnum.FASTLANE_JOIN_TELEGRAM]: FastlaneQuestTaskJoinTelegramGroup,
    [QuestTaskTypeEnum.FASTLANE_REFER_FRIEND]: FastlaneQuestTaskReferFriends,
  },
  allTaskCompletedComponent: FastlaneQuestAllTasksCompleted,
};

export const questsList: Quest[] = [
  {
    id: '1',
    name: 'Rabble',
    tag: 'USDT',
    image: rabbleEarnHeader,
    rewardDetailsHeader: rabbleEarnHeader,
    description:
      'Utilize your social graph by referring Rabble to your friends! Earn $USDT for each user that joins Rabble using your referral link!',
  },
  monadQuest,
  fastlaneQuest,
];
