import type {
  TListUserNotificationsResDto,
  TListUserUnreadNotificationsResDto,
  TMarkUserNotificationAsReadReqDto,
} from './dtos/user-notification.dto';
import type { HttpClient } from './http';

export class UserNotificationService {
  constructor(private readonly httpClient: HttpClient) {}

  public listUserNotifications(page: number, size: number): Promise<TListUserNotificationsResDto> {
    return this.httpClient.get(`/user-notifications?page=${page}&size=${size}`);
  }

  public listUserUnreadNotifications(): Promise<TListUserUnreadNotificationsResDto> {
    return this.httpClient.get('/user-notifications/unread');
  }

  public markUserNotificationAsRead(body: TMarkUserNotificationAsReadReqDto): Promise<void> {
    return this.httpClient.post('/user-notifications/read', body);
  }
}
