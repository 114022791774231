import { createSlice } from '@reduxjs/toolkit';
import type { TGetRabbleUserResDto } from '../../apis/dtos';

export type TUserState = {
  me?: TGetRabbleUserResDto;
};

// Initial state
const initialState: TUserState = {
  me: undefined,
};

// Create Redux slice
const userSlice = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    setMe: (state, action: { payload: { me?: TGetRabbleUserResDto } }) => {
      state.me = action.payload.me;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
