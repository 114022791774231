import { z } from 'zod';
import { UserNotificationActionTypeEnum, UserNotificationTypeEnum } from '../enums/notification.enum';

export const UserNotificationsResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(UserNotificationTypeEnum),
  actionType: z.nativeEnum(UserNotificationActionTypeEnum),
  title: z.string(),
  content: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const ListUserNotificationsResTransformer = z.array(UserNotificationsResTransformer);

export const ListUserUnreadNotificationsResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(UserNotificationTypeEnum),
  actionType: z.nativeEnum(UserNotificationActionTypeEnum),
  title: z.string(),
  content: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
}).array();

export const MarkUserNotificationAsReadReqTransformer = z.object({
  notificationIds: z.string().array(),
});
