/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks-static-deps/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from '../../../../../lib/teact/teactn';
import {
  getActions,
  getGlobal,
  setGlobal,
  withGlobal,
} from '../../../../../global';

import '../../QuestDetails.scss';

import claimStep3 from '../../../../images/earn/quests/monad/monad-claim-step-3.png';
import referFriendsBanner from '../../../../images/earn/quests/monad/monad-refer-friends-banner.png';
import useCopyToClipboard from '../../../../utils/useCopyToClipboard';
import { useEffect, useState } from '../../../../../lib/teact/teact';
import { CampaignEnum } from '../../../../apis/enums';
import pluto from '../../../../apis/pluto';
import { HowToClaim1 } from '../../../../images/earn/quests/monad/HowToClaim1';
import { HowToClaim2 } from '../../../../images/earn/quests/monad/HowToClaim2';
import { HowToClaim3 } from '../../../../images/earn/quests/monad/HowToClaim3';
import { HowToClaimArrow } from '../../../../images/earn/quests/monad/HowToClaimArrow';
import { useStore } from '../../../../teact-redux/useStore';
import { useDispatch } from '../../../../teact-redux/useDispatch';
import EarnActions from '../../../../teact-redux/actions/earn';
import { CheckIcon2 } from '../../../../../components/common/icons/CheckIcon2';

export const MonadQuestTaskReferFriends = () => {
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const [referralCount, setReferralCount] = useState(0);

  const { showNotification } = getActions();
  const me = useStore((store) => store.user.me);

  const inviteCode = me?.inviteCode;

  const appUrl = process.env.APP_URL;

  function handleCodeCopyToClipboard() {
    copyToClipboard(
      `Hey! Join this quest on Rabble using my link, and you'll get $MON. Let's earn together! ${appUrl}?referrer=${inviteCode}&utm=MONAD`,
    );
    showNotification({ message: 'Message Copied to Clipboard' });
  }

  const fetchReferralCount = async () => {
    const result = await pluto.services.earn.getReferralCountByCampaignType({
      questCampaignType: CampaignEnum.MONAD,
    });
    setReferralCount(result.referralCount);
  };

  useEffect(() => {
    fetchReferralCount();
  }, []);

  return (
    <div className="monad-quest-container monad">
      <div className="rewards__bottom-content monad">
        <div className="how-to-claim monad-rewards">
          <h3 className="title">How to Claim</h3>
          <div className="claim-process">
            <HowToClaimArrow />
            <div className="steps">
              <div className="first-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim1 className="icon" />
                <p className="step-text">Join the Rabble Telegram group</p>
              </div>
              <div className="third-step done-step">
                <CheckIcon2 className="check-icon" />
                <HowToClaim3 className="icon" />
                <p className="step-text">Agent Swaps For You</p>
              </div>
              <div className="second-step active-step">
                <HowToClaim2 className="icon" />
                <p className="step-text">Invite 2 Friends To Rabble</p>
              </div>
            </div>
          </div>
        </div>
        <div className="claim-card">
          <h3 className="title">Claim Now</h3>
          <p className="sub-title">2/3 Steps Completed</p>
          <p className="sub-title">{referralCount}/2 invited</p>
          <div className="claim-step-img">
            <img src={claimStep3} alt="" />
          </div>
          <p className="description">
            Get 0.2 $MON for every friend who joins Rabble with your link
          </p>
          <button onClick={handleCodeCopyToClipboard} className="claim-button">
            {isCopied ? 'Copied!' : 'Copy Message'}
          </button>
        </div>
      </div>
      <div className="monad-rewards images-container">
        <h3 className="title">3. Invite 2 Friends To Rabble</h3>
        <img src={referFriendsBanner} alt="" />
      </div>
    </div>
  );
};
