/* eslint-disable react/jsx-props-no-spreading */
import React from '../../../../../lib/teact/teact';
import circle from './circle.svg';
import triangle from './triangle.svg';

import './HowToClaimArrow.scss';

export const HowToClaimArrow = (props: any) => {
  return (
    <div className="arrow-container" {...props}>
      <img src={circle} alt="" />
      <div className="dashed-line" />
      <img src={triangle} alt="" />
    </div>
  );
};
