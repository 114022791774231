/* eslint-disable import/no-cycle */
import { Services } from '..';
import { getActions } from '../../../global';
import { MiddleScreen } from '../../../types';
import { monadQuest } from '../../earn/quests/quest-list-data';
import { earnActions } from '../../teact-redux/features/earn-slice';
import { layoutActions } from '../../teact-redux/features/layout-slice';
import { userActions } from '../../teact-redux/features/user-slice';
import StoreHolder from '../../teact-redux/storeHolder';
import { getQueryParam } from '../../utils/getQueryParams';
import type { TGetRabbleUserResDto } from '../dtos';
import { CampaignEnum, EventEnum } from '../enums';
import { HttpClient } from '../http';
import { LocalStorageService } from '../storage';

export class Pluto {
  private localStorage: LocalStorageService;

  public services: Services;

  public wallet ?: TGetRabbleUserResDto;

  constructor() {
    this.localStorage = new LocalStorageService();
    const accessToken = this.getAccessTokenFromStorage();
    const refreshToken = this.getRefreshTokenFromStorage();
    this.services = this.initServices(accessToken, refreshToken);
  }

  get dispatch() {
    return StoreHolder.getStore().dispatch;
  }

  public async connect(
    {
      telegramId,
      firstName,
      lastName,
      username,
      rabbleEthWallet,
      rabbleSolWallet,
      rabbleAptosWallet,
      rabbleSuiWallet,
      rabbleTonWallet,
    }: { telegramId:string;
      firstName?:string;
      lastName?:string;
      username?:string;
      rabbleEthWallet?: string;
      rabbleSolWallet?: string;
      rabbleAptosWallet?: string;
      rabbleSuiWallet?: string;
      rabbleTonWallet?: string; },
  ) : Promise<any> {
    try {
      const accessTokenFromStorage = this.localStorage.getItem('accessToken');

      if (!accessTokenFromStorage) {
        throw new Error('Access token is missing');
      }

      const me = await this.services.user.getMe();

      const isEthWalletOnDb = me.rabbleEthWallet !== null && !!me.rabbleEthWallet;
      const isSolWalletOnDb = me.rabbleSolWallet !== null && !!me.rabbleSolWallet;
      const isAptosWalletOnDb = me.rabbleAptosWallet !== null && !!me.rabbleAptosWallet;
      const isSuiWalletOnDb = me.rabbleSuiWallet !== null && !!me.rabbleSuiWallet;
      const isTonWalletOnDb = me.rabbleTonWallet !== null && !!me.rabbleTonWallet;

      if (!isEthWalletOnDb || !isSolWalletOnDb || !isAptosWalletOnDb || !isSuiWalletOnDb || !isTonWalletOnDb) {
        await this.services.user.createUser(
          {
            telegramId,
            firstName,
            lastName,
            username,
            rabbleEthWallet: rabbleEthWallet as string,
            rabbleSolWallet,
            rabbleAptosWallet,
            rabbleSuiWallet,
            rabbleTonWallet,
          },
        );
      }

      this.setWallet(me);
      this.dispatch(userActions.setMe({
        me,
      }));

      return me;
    } catch (error) {
      const referrer = getQueryParam('referrer');
      const utm = getQueryParam('utm');
      const campaign = getQueryParam('utm');
      const { openEarn } = getActions();
      if (campaign === CampaignEnum.MONAD) {
        if (window.innerWidth <= 768) {
          openEarn({});
          this.dispatch(earnActions.setSelectedQuest({ selectedQuest: monadQuest }));
          this.dispatch(layoutActions.setActiveRabbleFeature('earn'));
          this.dispatch(earnActions.setSelectedQuestId({ selectedQuestId: monadQuest.id }));
        } else {
          this.dispatch(layoutActions.setActiveRabbleFeature('earn'));
          this.handleChangeEarnScreen(MiddleScreen.Earn);
          this.dispatch(earnActions.setSelectedQuest({ selectedQuest: monadQuest }));
          this.dispatch(earnActions.setSelectedQuestId({ selectedQuestId: monadQuest.id }));
        }
      }
      const isValidCampaign = campaign != null && Object.values(CampaignEnum).includes(campaign as CampaignEnum);

      const token = await this.services.user.createUser(
        {
          telegramId,
          firstName,
          lastName,
          username,
          rabbleEthWallet: rabbleEthWallet as string,
          rabbleSolWallet,
          rabbleAptosWallet,
          rabbleSuiWallet,
          referrer: referrer || undefined,
          utm: utm || undefined,
          campaign: isValidCampaign ? (campaign as CampaignEnum) : undefined,
        },
      );

      this.resetAuthTokens({
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      });
      await this.services.earn.createEvent({ event: EventEnum.LOGIN_0 });
      const me = await this.services.user.getMe();
      await this.services.realm.createUser({
        id: telegramId,
        firstName: firstName || '',
        lastName: lastName || '',
        username: username || '',
        isBot: false,
      });
      this.setWallet(me);
      this.dispatch(userActions.setMe({
        me,
      }));

      return me;
    }
  }

  // -------------------------------PRIVATE--------------------------------- //

  private createHttpClient(
    accessToken?: string,
    refreshToken?: string,
  ) {
    const baseUrl = process.env.SERVER_URL!;
    return new HttpClient({
      baseUrl,
      accessToken,
      refreshToken,
      onTokenRefresh: this.resetAuthTokens.bind(this),
    });
  }

  private handleChangeEarnScreen(screen: MiddleScreen) {
    this.dispatch(
      layoutActions.setCurrentMiddleScreen({
        currentScreen: screen,
      }),
    );
  }

  private initServices(
    accessToken: string | null,
    refreshToken: string | null,
  ) {
    const httpClient = this.createHttpClient(accessToken ?? undefined, refreshToken ?? undefined);
    const services = new Services(httpClient);
    this.services = services;
    return services;
  }

  private resetAuthTokens(
    tokens: { accessToken: string; refreshToken: string },
  ) {
    this.initServices(tokens.accessToken, tokens.refreshToken);
    this.setAccessTokenInStorage(tokens.accessToken);
    this.setRefreshTokenInStorage(tokens.refreshToken);
  }

  private getAccessTokenFromStorage() {
    return this.localStorage.getItem<string>('accessToken');
  }

  private getRefreshTokenFromStorage() {
    return this.localStorage.getItem<string>('refreshToken');
  }

  private setAccessTokenInStorage(accessToken: string) {
    this.localStorage.setItem('accessToken', accessToken);
  }

  private setRefreshTokenInStorage(refreshToken: string) {
    this.localStorage.setItem('refreshToken', refreshToken);
  }

  private setWallet(wallet: TGetRabbleUserResDto) {
    this.wallet = wallet;
  }
}

const pluto = new Pluto();
export default pluto;
