/* eslint-disable react-hooks-static-deps/exhaustive-deps */
import { useEffect, useState } from '../../lib/teact/teact';
import StoreHolder from './storeHolder';
import type { RootState } from './store';

export function useStore<T>(selector: (state: RootState) => T): T {
  const store = StoreHolder.getStore();
  const [state, setState] = useState(selector(store.getState()));

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(selector(store.getState()));
    });
    return unsubscribe;
  }, []);

  return state;
}
