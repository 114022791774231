/* eslint-disable @typescript-eslint/no-unused-vars */
import './util/handleError';
import './util/setupServiceWorker';
import './global/init';

import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import { getActions, getGlobal } from './global';

import {
  DEBUG,
  MULTITAB_LOCALSTORAGE_KEY,
  STRICTERDOM_ENABLED,
} from './config';
import { enableStrict, requestMutation } from './lib/fasterdom/fasterdom';
import { selectTabState } from './global/selectors';
import { betterView } from './util/betterView';
import {
  establishMultitabRole,
  subscribeToMasterChange,
} from './util/establishMultitabRole';
import { initLocalization } from './util/localization';
import {
  requestGlobal,
  subscribeToMultitabBroadcastChannel,
} from './util/multitab';
import { checkAndAssignPermanentWebVersion } from './util/permanentWebVersion';
import { onBeforeUnload } from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import { IS_MULTITAB_SUPPORTED } from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';
import { TeactReduxProvider } from './rabble/teact-redux/teact-redux';
import { store } from './rabble/teact-redux/store';
import MobilePwaWrapper from './rabble/components/MobilePwaWrapper';
import { BrowserRouter, Route, Routes } from './rabble/teact-router/Router';
import Wallet from './rabble/wallet/main/Wallet';
import Earn from './rabble/earn/Earn';
import RabbleMainHeader from './rabble/main-header/RabbleMainHeader';
import Rabots from './rabble/rabot/Rabots';
import WalletModal from './rabble/wallet/main/WalletModal';
import Notification from './components/ui/Notification';
import Notifications from './rabble/notifications/Notifications';

if (STRICTERDOM_ENABLED) {
  enableStrict();
}

init();

async function init() {
  // const mixPanelProjectToken = process.env.MIXPANEL_PROJECT_TOKEN!;
  // const mixPanelApiHost = process.env.MIXPANEL_PROXY!;
  const mixPanelProjectToken = 'c8d48835fe0aef17e1a8c7abacd1d8d6';
  const mixPanelApiHost = 'https://proxy.mp.buidl.so';
  mixpanel.init(mixPanelProjectToken, {
    debug: true,
    verbose: true,
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
    api_host: mixPanelApiHost,
  });

  // posthog init
  posthog.init('phc_t0YGer6xjUua7fgUm0ybQy50fEw0FVfAXdCCWWAKm9o', {
    api_host: 'https://rabble-posthog-proxy.vercel.app',
    person_profiles: 'identified_only',
    disable_session_recording: false,
  });
  // posthog.debug();

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> INIT');
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();
    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  const global = getGlobal();

  initLocalization(global.settings.byKey.language, true);

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMasterChange((isMasterTab) => {
      getActions().switchMultitabRole(
        { isMasterTab },
        { forceSyncOnIOs: true },
      );
    });
    const shouldReestablishMasterToSelf = getGlobal().authState !== 'authorizationStateReady';
    establishMultitabRole(shouldReestablishMasterToSelf);
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> START INITIAL RENDER');
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(
      <TeactReduxProvider store={store}>
        {/* <MobilePwaWrapper> */}
        <App />

        {/* </MobilePwaWrapper> */}
      </TeactReduxProvider>,
      document.getElementById('root')!,
    );

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> FINISH INITIAL RENDER');
  }

  if (DEBUG) {
    document.addEventListener('dblclick', () => {
      // eslint-disable-next-line no-console
      console.warn('TAB STATE', selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn('GLOBAL STATE', getGlobal());
    });
  }
}

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
