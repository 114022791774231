import { configureStore } from '@reduxjs/toolkit';
import layoutReducer from './features/layout-slice';
import rabotsReducer from './features/rabots-slice';
import earnReducer from './features/earn-slice';
import userReducer from './features/user-slice';
import walletReducer from './features/wallet-slice';
import walletSwapReducer from './features/wallet-swap-slice';
import notificationsReducer from './features/notifications-slice';

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    rabots: rabotsReducer,
    earn: earnReducer,
    user: userReducer,
    wallet: walletReducer,
    walletSwap: walletSwapReducer,
    notifications: notificationsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
