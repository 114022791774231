export enum UserNotificationTypeEnum {
  TOKEN_RECIEVED = 'TOKEN_RECIEVED',
  TOKEN_DEBITED = 'TOKEN_DEBITED',
  QUEST_COMPLETED = 'QUEST_COMPLETED',
  INVITE_ACCEPTED = 'INVITE_ACCEPTED',
  MISSING_EMAIL = 'MISSING_EMAIL',
}

export enum UserNotificationActionTypeEnum {
  OPEN_WALLET = 'OPEN_WALLET',
  OPEN_EARN = 'OPEN_EARN',
  OPEN_EARN_MONAD_QUEST = 'OPEN_EARN_MONAD_QUEST',
  OPEN_RABOT = 'OPEN_RABOT',
  OPEN_EXTERNAL_LINK = 'OPEN_EXTERNAL_LINK',
  OPEN_TRANSACTION_HISTORY = 'OPEN_TRANSACTION_HISTORY',
}

export enum ProductNotificationTypeEnum {
  FEATURE_UPDATE = 'FEATURE_UPDATE',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export enum ProductNotificationActionTypeEnum {
  OPEN_WALLET = 'OPEN_WALLET',
  OPEN_EARN = 'OPEN_EARN',
  OPEN_RABOT = 'OPEN_RABOT',
  OPEN_EXTERNAL_LINK = 'OPEN_EXTERNAL_LINK',
}
